import memoize from 'lodash/memoize';
import {NEW_REPORTS} from './imports';

const getActiveReportsList = memoize((data) => {
    const placementReport = data.find(row => row.id === NEW_REPORTS.PLACEMENT_REPORT);
    const deliveryReport = data.find(row => row.id === NEW_REPORTS.DELIVERY_REPORT);
    const deliveryAndDomainReport = data.find(row => row.id === NEW_REPORTS.DELIVERY_AND_DOMAIN_REPORT);
    const deliveryAndDeviceReport = data.find(row => row.id === NEW_REPORTS.DELIVERY_AND_DEVICE_REPORT);
    const deliveryDomainAndDeviceReport = data.find(row => row.id === NEW_REPORTS.DELIVERY_DOMAIN_AND_DEVICE_REPORT);

    return [
        placementReport,
        deliveryReport,
        deliveryAndDomainReport,
        deliveryAndDeviceReport,
        deliveryDomainAndDeviceReport
    ]
});

export default getActiveReportsList;
